import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../shared/constance-values/constance-values';
import {PersonLogin} from '../../../core/sdk/model-dto';
import {PersonService} from '../../../shared/services/person.service';
import {SpinnerService} from '../../../shared/services/spinner.service';
import {NotificationService} from '../../../shared/services/notification.service';
import {NotificationMessageType} from '../../../shared/enums/NotificationMessageType';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateModule} from '../../../core/translations/translate.module';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-qr-code-form',
  templateUrl: './qr-code-form.component.html',
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgClass
  ]
})
export class QrCodeFormComponent implements OnInit {

  public formGroup: FormGroup;

  @Output() public submitFormEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private personService: PersonService,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
  }

  public onSubmit(): void {
    const PERSON_LOGIN: PersonLogin = {email: this.emailControl.value, password: this.passwordControl.value};
    this.spinnerService.activateSpinner();
    this.personService.createQRLoginCode(PERSON_LOGIN).subscribe({
      next: (response: string) => {
        this.notificationService.displayNotificationToast('user-self-management.qr-code-created-successfully',
          NotificationMessageType.SUCCESS);
        this.submitFormEmitter.emit(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]]
    });
  }

  public get emailControl(): AbstractControl {
    return this.formGroup.get('email');
  }

  public get passwordControl(): AbstractControl {
    return this.formGroup.get('password');
  }

  public onCancel(): void {
    this.closeViewEmitter.emit();
  }
}
